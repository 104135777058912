import React from 'react';
import ContactFormV1 from '../../../../../gatsby-theme-minimal/src/componentsList/ContactFormV1';
import PrivateDiningForm from './PrivateEventsForm';
export default function ContactFormsContainer(props) {
  const {
    pageObjectKey,
    businessData,
    configData,
    poweredImages,
    pageTitle,
    isMultiLocationSite,
    locationIndex,
  } = props;

  return (
    <>
      {pageObjectKey === 'contact' ? (
        <ContactFormV1
          {...props}
          businessData={businessData}
          configData={configData}
          pageTitle={pageTitle}
          isMultiLocationSite={isMultiLocationSite}
          variantName={configData.contactForm.variantName}
          options={configData.contactForm.options}
        />
      ) : (
        ''
      )}
      {/* //  ! =============================================================*/}
      {/* //  ! WHEN Shaodwing Add Additional Contact Forms Here By Page Key */}
      {/* //  ! =============================================================*/}

      {pageObjectKey === 'private-events' ? (
        <PrivateDiningForm
          {...props}
          businessData={businessData}
          configData={configData}
          pageTitle={pageTitle}
          isMultiLocationSite={isMultiLocationSite}
          variantName={configData.contactForm.variantName}
          options={configData.contactForm.options}
          formName={'private-events-form'}
        />
      ) : (
        ''
      )}
    </>
  );
}
