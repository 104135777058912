import React from 'react';
import {
  Flex,
  Heading,
  Text,
  Textarea,
  Input,
  Button,
  Label,
  Select,
} from '@theme-ui/components';

import ContactWidget from '../../../../../gatsby-theme-minimal/src/components/General/ContactDetails';
// import cloudinaryImageOptimizer from '../../../helpers/cloudinaryImageOptimizer'
import backgroundStylesGradient from '../../../../../gatsby-theme-minimal/src/helpers/backgroundStylesGradient';

export default function ContactForm({
  businessData,
  configData,
  options,
  variantName,
  poweredImages,
  locationIndex,
  isMultiLocationSite,
}) {
  const { name, street, city, state, zip, phone } = businessData;

  const {
    containerStyles,
    title,
    subtitle,
    text,
    contactDetails,
    email,
    backgroundImages,
    backgroundGradient,
  } = options;

  // const [cloudinaryImage, setCloudinaryImage] = useState(undefined)

  const formName = `private-dining-form-${city}`;

  const styles = containerStyles ? JSON.parse(containerStyles) : {};
  const variant = variantName ? variantName : 'contactFormV1';

  const backgroundStyles = backgroundImages
    ? backgroundStylesGradient(
        backgroundImages[locationIndex],
        backgroundGradient,
        poweredImages,
        locationIndex
      )
    : '';

  return (
    <Flex
      variant={`${variant}.container`}
      sx={{
        backgroundImage: backgroundStyles,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        ...styles,
      }}
    >
      {title && (
        <Heading as='h2' variant={`${variant}.title`} className='title'>
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading as='h3' variant={`${variant}.subtitle`} className='subtitle'>
          {subtitle}
        </Heading>
      )}
      {text && (
        <Text as='p' variant={`${variant}.text`} className='text'>
          {text}
        </Text>
      )}

      {contactDetails && (
        <>
          <ContactWidget
            phone={phone}
            address={{ name, street, city, state, zip }}
            email={email}
            alternativeGoogleMapLink={configData.alternativeGoogleMapLink}
          />
          <br></br>
        </>
      )}

      <Flex
        as='form'
        variant={`${variantName}.formContainer`}
        name={formName ? formName : 'contactform'} //  this can not be dyanmic form names and details must be static      method='POST'
        method='POST'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        sx={customStyles.form}
      >
        <Input
          type='hidden'
          name='form-name'
          value={formName ? formName : 'contactform'}
        />
        <Heading
          as='h3'
          sx={customStyles.formSectionTitle}
          className='formSectionTitle'
        >
          Your Contact Information
        </Heading>
        <Label sx={customStyles.label}>Name</Label>
        <Input
          variant={`${variantName}.name`}
          name='name'
          type='text'
          id='name'
          mb={3}
          placeholder='What Is Your Name?'
          className='inputField name'
          required
        />
        <Label sx={customStyles.label}>Phone</Label>
        <Input
          variant={`${variantName}.email`}
          type='phone'
          name='phone'
          id='phone'
          mb={3}
          placeholder='What Is Your Contact Number?'
          className='inputField email'
        />
        <Label sx={customStyles.label}>Email</Label>
        <Input
          variant={`${variantName}.email`}
          type='email'
          name='email'
          id='email'
          mb={3}
          placeholder='Your Email Address'
          className='inputField email'
          required
        />
        <Label sx={customStyles.label}>Company</Label>
        <Input
          variant={`${variantName}.name`}
          name='company'
          type='text'
          id='company'
          mb={3}
          placeholder='Your Company Name (If Applicable)'
          className='inputField name'
        />
        <Label sx={customStyles.label}>Type Of Event</Label>
        <Textarea
          name='eventnature'
          id='eventnature'
          variant={`${variantName}.message`}
          rows='6'
          mb={3}
          placeholder='What Is The Nature Of This Event'
          className='textField message eventnature'
          required
        />

        <Heading
          as='h3'
          sx={customStyles.formSectionTitle}
          className='formSectionTitle'
        >
          Event Details
        </Heading>

        <Label sx={customStyles.label}>Date Of Event</Label>
        <Input
          variant={`${variantName}.email`}
          type='date'
          name='date'
          id='date'
          mb={3}
          placeholder='Event Date'
          className='inputField email'
        />
        <Label sx={customStyles.label}>Event Time</Label>
        <Input
          variant={`${variantName}.email`}
          type='time'
          name='eventtime'
          id='eventtime'
          mb={3}
          placeholder='Event Time'
          className='inputField email'
        />

        <Label sx={customStyles.label}>Number Of Guests</Label>
        <Input
          variant={`${variantName}.email`}
          type='number'
          name='numberofguests'
          id='numberofguests'
          mb={3}
          placeholder='Number Of People'
          className='inputField email'
        />

        <Label sx={customStyles.label}>Additional Information</Label>

        <Textarea
          name='additionalinfomation'
          id='additionalinfomation'
          variant={`${variantName}.message`}
          rows='6'
          mb={3}
          placeholder='Is There Any Additional Information You Would Like To Add?'
          className='textField message'
          required
        />

        <Label sx={customStyles.label}>How did you hear about us?</Label>

        <Select
          defaultValue='Select'
          variant={`${variantName}.email`}
          className='inputField email'
          sx={customStyles.dropdown}
        >
          <option>Select</option>
          <option>Instagram</option>
          <option>Facebook</option>
          <option>Search Engine</option>
          <option>Email</option>
          <option>Other</option>
        </Select>
        <br />
        {isMultiLocationSite ? (
          <Input
            sx={{ display: 'none !important' }}
            name='location'
            id='location'
            value={city}
            placeholder={city}
          />
        ) : (
          ''
        )}
        <Button className='button submitBtn' type='submit'>
          Submit
        </Button>
      </Flex>
    </Flex>
  );
}

const customStyles = {
  form: {
    'input::-webkit-calendar-picker-indicator': {
      filter: 'brightness(0) invert(1)',
    },
  },
  label: {
    color: 'white',
    fontFamily: 'heading',
    fontWeight: 'bold',
    // marginBottom: '0.5rem',
    padding: '0.25rem',
    backgroundColor: '#cb000659',
    width: 'fit-content',
    borderRadius: '7.5px 7.5px 0px 0px',
  },
  formSectionTitle: {
    color: 'white',
    textAlign: 'left',
    margin: '1rem 0rem 2rem',
    borderBottom: '3px solid white',
    padding: '1rem 0rem',
  },
  dropdown: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    border: 'none',
    borderBottom: '1px solid',
    borderColor: 'primary',
    color: 'white',
  },
};
